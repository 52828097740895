import { useState } from "react";
import { Badge, OverlayTrigger, Popover, Toast } from "react-bootstrap";

import "../sidebar/SideBar.css"

function SideBar(){

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Popover right</Popover.Header>
            <Popover.Body>
            And here's some <strong>amazing</strong> content. It's very engaging.
            right?
            </Popover.Body>
        </Popover>
    );

    const [showA, setShowA] = useState(true);

    const toggleShowA = () => setShowA(!showA);

    return(
        <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom bg-light">Diagnostico Fácil</div>
            <div className="list-group list-group-flush">
                <a className="ListGroupTextAlign list-group-item list-group-item-action list-group-item-light p-1" href="/#">Modalidades</a>
                <div className="SideBarElements">
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Badge size="sm" variant="success">Virosse</Badge>
                    </OverlayTrigger>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Badge size="sm" variant="success">Virosse</Badge>
                    </OverlayTrigger>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Badge size="sm" variant="success">Virosse</Badge>
                    </OverlayTrigger>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Badge size="sm" variant="success">Virosse</Badge>
                    </OverlayTrigger>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <>
                        <Badge as={'a'} pill bg="primary" onClick={toggleShowA} className="mb-2">
                            Virose
                        </Badge>
                        <Toast show={showA} onClose={toggleShowA}>
                        <Toast.Header>
                            <strong className="me-auto">Virose</strong>
                            <small>Categorias</small>
                        </Toast.Header>
                        <Toast.Body>
                            <div className="SideBarElements">
                                <Badge onClick={toggleShowA} className="mb-2">
                                    virose
                                </Badge>
                                <Badge onClick={toggleShowA} className="mb-2">
                                    virose
                                </Badge>
                                <Badge onClick={toggleShowA} className="mb-2">
                                    virose
                                </Badge>
                                <Badge onClick={toggleShowA} className="mb-2">
                                    virose
                                </Badge>
                            </div>
                        </Toast.Body>
                        </Toast>
                        </>
                    </OverlayTrigger>
                </div>    
            </div>
        </div>
    );
}

export default SideBar;