import Container from "./components/container/Container";
import NavBar from "./components/navbar/NavBar";
import SideBar from "./components/sidebar/SideBar";


function App() {
  return (
    <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
            <NavBar />
            <Container />
        </div>
    </div>
  );
}

export default App;
