import { Button, OverlayTrigger, Popover} from "react-bootstrap";

function Container(){

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Popover right</Popover.Header>
            <Popover.Body>
            And here's some <strong>amazing</strong> content. It's very engaging.
            right?
            </Popover.Body>
        </Popover>
    );
      

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <br/>
                    <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Buscar"/>
                    </form>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-sm">   
                    <div className="card">
                        <img className="card-img-top" src="https://thumbs.dreamstime.com/b/bact%C3%A9rias-7116413.jpg" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            {/* <button type="button" className="btn btn-primary" data-toggle="popover" title="teste de popping button">Click to toggle popover</button> */}
                            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                <Button variant="success">Click me to see</Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                <div className="col-sm">   
                    <div className="card">
                        <img className="card-img-top" src="https://thumbs.dreamstime.com/b/bact%C3%A9rias-7116413.jpg" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="/#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm">   
                    <div className="card">
                        <img className="card-img-top" src="https://thumbs.dreamstime.com/b/bact%C3%A9rias-7116413.jpg" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="/#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-sm">   
                    <div className="card">
                        <img className="card-img-top" src="https://thumbs.dreamstime.com/b/bact%C3%A9rias-7116413.jpg" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="/#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm">   
                    <div className="card">
                        <img className="card-img-top" src="https://thumbs.dreamstime.com/b/bact%C3%A9rias-7116413.jpg" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="/#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm">   
                    <div className="card">
                        <img className="card-img-top" src="https://thumbs.dreamstime.com/b/bact%C3%A9rias-7116413.jpg" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="/#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Container;